import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/aws.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/bitcoin-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/bitcoin.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/bloomberg-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/bloomberg.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/chain-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/chain.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/cointelegraph-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/cointelegraph.svg");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/decrypt-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/decrypt.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/forbes-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/forbes.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/google-cloud.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/lifi-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/lifi.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/microsoft.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/mirada-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/mirada.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/nvidia.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/poolz-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/poolz.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/preview.svg");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/solana-dark.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/solana.png");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/yahoo-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/(main)/_images/yahoo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["TokenBg"] */ "/app/app/[locale]/(main)/_ui/Token/TokenBg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TokenImage"] */ "/app/app/[locale]/(main)/_ui/Token/TokenImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/src/shared/ui/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/app/src/shared/ui/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/shared/ui/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
